<template>
  <b-overlay
    :show="loading"
    style="min-height: 20vw; width: 100%"
    opacity="0.5"
  >
    <div class="dashboard-company-container">
      <div
        class="info"
      >
        <div
          v-if="name"
          class="name"
        >
          {{ name }}
        </div>
        <div
          v-if="area"
          class="area"
        >
          <font-awesome-icon
            :icon="industries[area].icon"
            class="mr-10 fs-16"
          />{{ industries[area].text }}
        </div>
        <div
          v-if="economic_sector"
          class="economic-sector"
        >
          {{ choices.find(el => el.value == economic_sector).label }}
        </div>
      </div>
      <div
        class="region"
      >
        <c-map
          v-if="region"
          :region="'R-'+region"
        />
        <c-map
          v-else
        />
        <div class="region-name">
          {{ regions['R-'+region] }}
        </div>
      </div>

      <div class="values">
        <div
          class="company-card"
          style="justify-content: space-between"
        >
          <div class="company-card-values">
            <div class="icon">
              <b-icon icon="people" />
            </div>
            <div>
              <div class="label">
                N.º Empregados
              </div>
              <div class="value">
                {{ marks_1[employees] }}
              </div>
            </div>
          </div>
          <div class="company-card-values">
            <img
              v-if="!approved"
              src="@/assets/TransicaoDigital.png"
              height="130px"
            > <img
              v-else
              src="@/assets/TransicaoDigital_Text.png"
              height="130px"
            >
          </div>
        </div>
        <div class="company-card">
          <div class="company-card-values">
            <div class="icon">
              <b-icon icon="briefcase" />
            </div>
            <div>
              <div class="label">
                Volume aproximado de negócios
              </div>
              <div class="value">
                {{ marks_2[turnover] }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="$store.getters.user.permission_id !== 2"
        class="users"
      >
        <div
          class="users-header"
        >
          <div class="title">
            Utilizadores registados
          </div>
          <div class="buttons justify-content-start my-0">
            <!-- <div
              v-if="newPermissionsUser"
              class="button primary"
              @click="() => {
                $bvModal.show('representUser');
              }"
            >
              <b-icon
                icon="arrow-repeat"
              />
              Transferir permissões
            </div> -->
            <div
              v-if="$store.getters.user.permission_id !== 2"
              class="button primary"
              @click="registerUser()"
            >
              <b-icon
                icon="person-plus"
              />
              Registar utilizador
            </div>
          </div>
        </div>
        <div
          v-for="(user, u) in users"
          :key="u"
          class="user"
        >
          <!--  <div
            v-if="user.id !== $store.getters.user.id"
            class="check"
          >
            <b-form-radio
              v-model="newPermissionsUser"
              :value="user.id"
            />
          </div>
          <div
            v-else
            class="check"
          >
            <b-form-radio
              v-model="newPermissionsUser"
              disabled
              :value="user.id"
            />
          </div> -->
          <div class="id">
            #{{ u + 1 }}
          </div>
          <div class="name">
            {{ user.name }}
          </div>
          <div class="email">
            {{ user.email }}
          </div>
          <div class="permissions">
            {{ permissions[user.permission_id] }}
          </div>
          <div
            class="buttons p-0 m-0 change-permissions"
          >
            <div
              v-if="user.id !== $store.getters.user.id && $store.getters.user.permission_id !== 2 && user.permission_id !== 3"
              class="button outlined no-border secondary sm"
              style="text-decoration: underline;"
              @click="() => {
                newPermissionsUser = user.id
                $bvModal.show('representUser');
              }"
            >
              Alterar para gestor
            </div>
          </div>
          <div />
        </div>
      </div>
      <b-modal
        id="representUser"
        header-class="border-bottom-0"
        footer-class="border-top-0"
        centered
        hide-footer
        size="lg"
      >
        <div>
          <div class="title">
            Transferir permissões
          </div>

          <div class="text-dark text-center">
            Ao aceitar estará a passar todas as suas permissões de gestão da sua empresa para o utilizador selecionado.
            Prentende continuar com o processo?
          </div>

          <div class="buttons">
            <button
              class="button secondary"
              type="submit"
              @click="() => {
                newPermissionsUser = null
                $bvModal.hide('representUser');
              }"
            >
              Cancelar
            </button>
            <button
              class="button primary"
              type="submit"
              @click="switchPermissions"
            >
              Aceitar
            </button>
          </div>
        </div>
      </b-modal>
      <b-modal
        id="registerUser"
        header-class="border-bottom-0"
        footer-class="border-top-0"
        centered
        hide-footer
        size="lg"
      >
        <div>
          <div class="title">
            Registar utilizador
          </div>
          <div
            v-if="error || success"
            class="feedback-row"
          >
            <div
              v-if="error"
              class="error-danger"
            >
              Ocorreu um erro, por favor tente submeter o questionário mais tarde!
            </div>
            <div
              v-if="success"
              class="error-success"
            >
              Utilizador registado com sucesso! Foi enviado um email para a caixa de email do novo utilizador de forma a concluir o processo de registo! Obrigado!

              Caso não tenha recebido o email, consulte a caixa de spam.
            </div>
          </div>
          <b-form
            class="text-left mt-5"
            @submit="submit"
          >
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-2"
                  label="Nome *"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="form.name"
                    required
                    placeholder="Nome"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-3"
                  label="Cargo"
                  label-for="input-3"
                >
                  <b-form-select
                    v-model="form.role"
                    :options="options"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group
              id="input-group-4"
              label="Email *"
              label-for="input-4"
            >
              <b-form-input
                id="input-4"
                v-model="form.email"
                type="email"
                required
                placeholder="Email"
              />
            </b-form-group>

            <div>* Campo de preenchimento obrigatório</div>
            <div class="buttons justify-content-start">
              <button
                class="button primary"
                type="submit"
              >
                Registar
              </button>
            </div>
          </b-form>
        </div>
      </b-modal>
    </div>
  </b-overlay>
</template>

<script>
import { get, post } from '../../services/api';
import Map from './Map/index.vue';
import title from '../../mixins/middlewares/title';

export default {
  name: 'DashboardCompany',
  components: {
    'c-map': Map,
  },
  data() {
    return {
      approved: false,
      newPermissionsUser: null,
      error: false,
      success: false,
      name: null,
      area: null,
      country: null,
      region: null,
      users: null,
      employees: null,
      turnover: null,
      know_the_concept: null,
      goals: null,
      causes: null,
      status: null,
      contact: null,
      economic_sector: null,
      clusters: null,
      loading: true,
      form: {
        email: '',
        name: '',
        role: '',
      },
      regions: {
        'R-1': 'Alto Minho',
        'R-2': 'Cávado',
        'R-3': 'Ave',
        'R-4': 'Área Metropolitana do Porto',
        'R-5': 'Alto Tâmega',
        'R-6': 'Tâmega e Sousa',
        'R-7': 'Douro',
        'R-8': 'Terras de Trás-os-Montes',
        'R-9': 'Oeste',
        'R-10': 'Região de Aveiro',
        'R-11': 'Região de Coimbra',
        'R-12': 'Região de Leiria',
        'R-13': 'Viseu Dão Lafões',
        'R-14': 'Beira Baixa',
        'R-15': 'Médio Tejo',
        'R-16': 'Beiras e Serra da Estrela',
        'R-17': 'Área Metroplotinada de Lisboa',
        'R-18': 'Alentejo Litoral',
        'R-19': 'Baixo Alentejo',
        'R-20': 'Lezíria do Tejo',
        'R-21': 'Alto Alentejo',
        'R-22': 'Alentejo Central',
        'R-23': 'Algarve',
        'R-24': 'Região Autónoma dos Açores',
        'R-25': 'Região Autónoma da Madeira',
      },
      industries: {
        1: {
          text: 'Agricultura, produção animal, caça, floresta e pesca',
          icon: 'tractor',
        },
        2: {
          text: 'Indústrias extractivas',
          icon: 'hard-hat',
        },
        3: {
          text: 'Indústrias transformadoras',
          icon: 'industry',
        },
        4: {
          text: 'Electricidade, gás, vapor, água quente e fria e ar frio',
          icon: 'charging-station',
        },
        5: {
          text: 'Captação, tratamento e distribuição de água; saneamento, gestão de resíduos e despoluição',
          icon: 'faucet',
        },
        6: {
          text: 'Construção',
          icon: 'hammer',
        },
        7: {
          text: 'Comércio por grosso e a retalho; reparação de veículos automóveis e motociclos',
          icon: 'wrench',
        },
        8: {
          text: 'Transportes e armazenagem',
          icon: 'truck',
        },
        9: {
          text: 'Alojamento, restauração e similares',
          icon: 'hotel',
        },
        10: {
          text: 'Actividades de informação e de comunicação',
          icon: 'tty',
        },
        11: {
          text: 'Actividades financeiras e de seguros',
          icon: 'piggy-bank',
        },
        12: {
          text: 'Actividades imobiliárias',
          icon: 'home',
        },
        13: {
          text: 'Actividades de consultoria, científicas, técnicas e similares',
          icon: 'flask',
        },
        14: {
          text: 'Actividades administrativas e dos serviços de apoio',
          icon: 'hands-helping',
        },
        15: {
          text: 'Administração Pública e Defesa; Segurança Social Obrigatória',
          icon: 'shield-alt',
        },
        16: {
          text: 'Educação',
          icon: 'university',
        },
        17: {
          text: 'Actividades de saúde humana e apoio social',
          icon: 'heartbeat',
        },
        18: {
          text: 'Actividades artísticas, de espectáculos, desportivas e recreativas',
          icon: 'paint-brush',
        },
        19: {
          text: 'Outras actividades de serviços',
          icon: 'taxi',
        },
        20: {
          text: 'Actividades das famílias empregadoras de pessoal doméstico e actividades de produção das famílias para uso próprio',
          icon: 'users',
        },
        21: {
          text: 'Actividades dos organismos internacionais e outras instituições extra-territoriais',
          icon: 'globe',
        },
      },
      options: [
        { value: '1', text: 'Gestão de topo / Dirigente superior' },
        { value: '2', text: 'Gestão intermédia / Dirigente intermédio' },
        { value: '3', text: 'Técnico(a) / Técnico(a) superior' },
        { value: '5', text: 'Outro' },
      ],
      marks_1: {
        1: 'Até 9',
        2: 'De 10 a 49',
        3: 'De 50 a 249',
        4: '250 ou mais',
      },
      marks_2: {
        0: 'Abaixo de 2 milhões de euros',
        1: 'Entre 2 e 10 milhões de euros',
        2: 'Entre 10 e 50 milhões de euros',
        3: 'Acima de 50 milhões de euros',
        4: 'Não especificado',
      },
      choices: [
        { label: 'Agricultura, produção animal, caça e actividades dos serviços relacionados', value: 1 },
        { label: 'Silvicultura e exploração florestal', value: 2 },
        { label: 'Pesca e aquicultura', value: 3 },
        { label: 'Extracção de hulha e lenhite', value: 5 },
        { label: 'Extracção de petróleo bruto e gás natural', value: 6 },
        { label: 'Extracção e preparação de minérios metálicos', value: 7 },
        { label: 'Outras indústrias extractivas', value: 8 },
        { label: 'Actividades dos serviços relacionados com as indústrias extractivas', value: 9 },
        { label: 'Indústrias alimentares', value: 10 },
        { label: 'Indústria das bebidas', value: 11 },
        { label: 'Indústria do tabaco', value: 12 },
        { label: 'Fabricação de têxteis', value: 13 },
        { label: 'Indústria do vestuário', value: 14 },
        { label: 'Indústria do couro e dos produtos do couro', value: 15 },
        { label: 'Indústrias da madeira e da cortiça e suas obras, excepto mobiliário; fabricação de obras de cestaria e de espartaria', value: 16 },
        { label: 'Fabricação de pasta, de papel, cartão e seus artigos', value: 17 },
        { label: 'Impressão e reprodução de suportes gravados', value: 18 },
        { label: 'Fabricação de coque, de produtos petrolíferos refinados e de aglomerados de combustíveis', value: 19 },
        { label: 'Fabricação de produtos químicos e de fibras sintéticas ou artificiais, excepto produtos farmacêuticos', value: 20 },
        { label: 'Fabricação de produtos farmacêuticos de base e de preparações farmacêuticas', value: 21 },
        { label: 'Fabricação de artigos de borracha e de matérias plásticas', value: 22 },
        { label: 'Fabricação de outros produtos minerais não metálicos', value: 23 },
        { label: 'Indústrias metalúrgicas de base', value: 24 },
        { label: 'Fabricação de produtos metálicos, excepto máquinas e equipamentos', value: 25 },
        { label: 'Fabricação de equipamentos informáticos, equipamento para comunicações e produtos electrónicos e ópticos', value: 26 },
        { label: 'Fabricação de equipamento eléctrico', value: 27 },
        { label: 'Fabricação de máquinas e de equipamentos, n.e.', value: 28 },
        { label: 'Fabricação de veículos automóveis, reboques, semi-reboques e componentes para veículos automóveis', value: 29 },
        { label: 'Fabricação de outro equipamento de transporte', value: 30 },
        { label: 'Fabricação de mobiliário e de colchões', value: 31 },
        { label: 'Outras indústrias transformadoras', value: 32 },
        { label: 'Reparação, manutenção e instalação de máquinas e equipamentos', value: 33 },
        { label: 'Electricidade, gás, vapor, água quente e fria e ar frio', value: 35 },
        { label: 'Captação, tratamento e distribuição de água', value: 36 },
        { label: 'Recolha, drenagem e tratamento de águas residuais', value: 37 },
        { label: 'Recolha, tratamento e eliminação de resíduos; valorização de materiais', value: 38 },
        { label: 'Descontaminação e actividades similares', value: 39 },
        { label: 'Promoção imobiliária (desenvolvimento de projectos de edifícios); construção de edifícios', value: 41 },
        { label: 'Engenharia civil', value: 42 },
        { label: 'Actividades especializadas de construção', value: 43 },
        { label: 'Comércio, manutenção e reparação, de veículos automóveis e motociclos', value: 45 },
        { label: 'Comércio por grosso (inclui agentes), excepto de veículos automóveis e motociclos', value: 46 },
        { label: 'Comércio a retalho, excepto de veículos automóveis e motociclos', value: 47 },
        { label: 'Transportes terrestres e transportes por oleodutos ou gasodutos', value: 49 },
        { label: 'Transportes por água', value: 50 },
        { label: 'Transportes aéreos', value: 51 },
        { label: 'Armazenagem e actividades auxiliares dos transportes(inclui manuseamento)', value: 52 },
        { label: 'Actividades postais e de courier', value: 53 },
        { label: 'Alojamento', value: 55 },
        { label: 'Restauração e similares', value: 56 },
        { label: 'Actividades de edição', value: 58 },
        { label: 'Actividades cinematográficas, de vídeo, de produção de programas de televisão, de gravação de som e de edição de música', value: 59 },
        { label: 'Actividades de rádio e de televisão', value: 60 },
        { label: 'Telecomunicações', value: 61 },
        { label: 'Consultoria e programação informática e actividades relacionadas', value: 62 },
        { label: 'Actividades dos serviços de informação', value: 63 },
        { label: 'Actividades de serviços financeiros, excepto seguros e fundos de pensões', value: 64 },
        { label: 'Seguros, resseguros e fundos de pensões, excepto segurança social obrigatória', value: 65 },
        { label: 'Actividades auxiliares de serviços financeiros e dos seguros', value: 66 },
        { label: 'Actividades imobiliárias', value: 68 },
        { label: 'Actividades jurídicas e de contabilidade', value: 69 },
        { label: 'Actividades das sedes sociais e de consultoria para a gestão', value: 70 },
        { label: 'Actividades de arquitectura, de engenharia e técnicas afins; actividades de ensaios e de análises técnicas', value: 71 },
        { label: 'Actividades de Investigação científica e de desenvolvimento', value: 72 },
        { label: 'Publicidade, estudos de mercado e sondagens de opinião', value: 73 },
        { label: 'Outras actividades de consultoria, científicas, técnicas e similares', value: 74 },
        { label: 'Actividades veterinárias', value: 75 },
        { label: 'Actividades de aluguer', value: 77 },
        { label: 'Actividades de emprego', value: 78 },
        { label: 'Agências de viagem, operadores turísticos, outros serviços de reservas e actividades relacionadas', value: 79 },
        { label: 'Actividades de investigação e segurança', value: 80 },
        { label: 'Actividades relacionadas com edifícios, plantação e manutenção de jardins', value: 81 },
        { label: 'Actividades de serviços administrativos e de apoio prestados às empresas', value: 82 },
        { label: 'Administração Pública e Defesa; Segurança Social Obrigatória', value: 84 },
        { label: 'Educação', value: 85 },
        { label: 'Actividades de saúde humana', value: 86 },
        { label: 'Actividades de apoio social com alojamento', value: 87 },
        { label: 'Actividades de apoio social sem alojamento', value: 88 },
        { label: 'Actividades de teatro, de música, de dança e outras actividades artísticas e literárias', value: 90 },
        { label: 'Actividades das bibliotecas, arquivos, museus e outras actividades culturais', value: 91 },
        { label: 'Lotarias e outros jogos de aposta', value: 92 },
        { label: 'Actividades desportivas, de diversão e recreativas', value: 93 },
        { label: 'Actividades das organizações associativas', value: 94 },
        { label: 'Reparação de computadores e de bens de uso pessoal e doméstico', value: 95 },
        { label: 'Outras actividades de serviços pessoais', value: 96 },
        { label: 'Actividades das famílias empregadoras de pessoal doméstico', value: 97 },
        { label: 'Actividades de produção de bens e serviços pelas famílias para uso próprio', value: 98 },
        { label: 'Actividades dos organismos internacionais e outras instituições extraterritoriais', value: 99 },
      ],
      permissions: {
        1: 'Administrador',
        2: 'Utilizador da empresa',
        3: 'Gestor da empresa',
      },
    };
  },
  async created() {
    await get(`company/${this.$store.getters.state.user.company_id}`).then((response) => {
      this.name = response.data.company.name;
      this.area = response.data.company.industry;
      this.employees = parseInt(response.data.company.employees, 10);
      this.turnover = parseInt(response.data.company.turnover, 10);
      this.know_the_concept = response.data.company.know_the_concept;
      this.goals = response.data.company.goals;
      this.causes = response.data.company.causes;
      this.status = response.data.company.status;
      this.contact = response.data.company.contact;
      this.economic_sector = response.data.company.economic_sector;
      this.country = response.data.company.country;
      this.region = false;
      this.$nextTick().then(() => {
        this.region = response.data.company.region;
      });
      this.clusters = response.data.company.clusters;
      this.users = response.data.users;
      this.approved = response.data.approved;
    });
    this.loading = false;
  },
  methods: {
    async submit(evt) {
      this.success = false;
      this.error = false;
      evt.preventDefault();

      const fields = JSON.parse(JSON.stringify(this.form));

      await post('auth/register', {
        name: fields.name,
        email: fields.email,
        role: fields.role,
        enable: false,
        partial: true,
        company: this.$store.getters.state.user.company_id,
      }).then((response) => {
        this.success = true;
      });
    },
    registerUser() {
      this.$bvModal.show('registerUser');
    },
    async switchPermissions() {
      await post(`switch-admin/${this.$store.getters.state.user.company_id}`, {
        user: this.newPermissionsUser,
      }).then(() => {
        this.$store.commit('resetPermissions');
        this.$bvModal.hide('representUser');

        this.success = true;
      });
    },
  },
};
</script>
